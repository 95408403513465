<ng-container *ngIf="isModal">
  <div class="modal-content">
    <!--header-->
    <div class="modal-header">
      <button type="button" [class]="isRtl ? 'close close-left' : 'close'" (click)="close()">&times;</button>
      <h3 class="modal-title" [style.color]="storeDesignOptions.color">{{translations?.OrderDeliveryOptions}}</h3>
    </div>
    <!--body-->
    <div class="modal-body modal-body-flow">
      <ng-content *ngTemplateOutlet="contentTemplate"></ng-content>
    </div>
    <div class="modal-footer">
      <div style="text-align: center;">
        <ion-button class="basic-btn ion-margin-horizontal" (click)="close()"
          [style.--background]="storeDesignOptions.color" [style.color]="storeDesignOptions.frontColor">
          <ion-label>
            <span translate>Close</span>
          </ion-label>
        </ion-button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isModal && !ouletList">
  <ng-content *ngTemplateOutlet="contentTemplate"></ng-content>
</ng-container>

<ng-container *ngIf="ouletList">
  <ng-content *ngTemplateOutlet="deliveryTypeSegmentTemplate"></ng-content>
</ng-container>

<ng-template #deliveryTypeSegmentTemplate>
  <ion-segment mode="ios" ngDefaultControl [value]="orderAppService.order.deliveryType"
    (ionChange)="onChangeDeliveryType($event)" [style.--background]="storeDesignOptions.frontColor" style="width: 102%">
    <ion-segment-button [value]="deliveryType.Delivery" *ngIf="outletsAppService.storeOutletsHasDelivery?.length>0"
      [style.color]="storeDesignOptions.color" [style.--color-checked]="storeDesignOptions.color">
      <span class="text-delivery-method" translate>Delivery</span>
    </ion-segment-button>
    <ion-segment-button [value]="deliveryType.SelfPickup" *ngIf="outletsAppService.storeOutletsHasSelfPickup?.length>0"
      [style.color]="storeDesignOptions.color" [style.--color-checked]="storeDesignOptions.color">
      <span class="text-delivery-method" translate>SelfPickup</span>
    </ion-segment-button>
    <ion-segment-button [value]="deliveryType.DineIn" *ngIf="outletsAppService.storeOutletsHasDineIn?.length>0"
      [style.color]="storeDesignOptions.color" [style.--color-checked]="storeDesignOptions.color">
      <span class="text-delivery-method" translate>DineIn</span>
    </ion-segment-button>
  </ion-segment>
</ng-template>
<ng-template #preperationTimeTemplate>
  <ion-toolbar class="flex align-item-center" *ngIf="orderAppService.isDeliveryTimeDisplayed()">
    <ion-icon slot="start" name="alarm-outline" class="icon- ion-padding-end"></ion-icon>
    <span class="bold ion-padding-end" translate>PreperationTime</span>
    <span class="bold" *ngIf="orderAppService?.order?.deliveryType == deliveryType.Delivery">
      ({{getDuration(currentOutlet?.deliveryLiveOrdersPreperationTime , true)}})</span>
    <span class="bold" *ngIf="orderAppService?.order?.deliveryType == deliveryType.SelfPickup">
      ({{getDuration(currentOutlet?.selfPickupLiveOrdersPreperationTime , true)}})</span>
    <span class="bold" *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn">
      ({{getDuration(currentOutlet?.dineInLiveOrdersPreperationTime , true)}})</span>
  </ion-toolbar>
</ng-template>

<ng-template #contentTemplate>
  <ng-container *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'">
    <div *ngIf="!orderAppService.isShoppingCartEmpty || homePage" [class.container]="homePage" [class.p-1]="homePage">
      <div *ngIf="outletsAppService.viewDeliveryType()">
        <div [class.ion-padding-start]="!homePage">
          <ion-toolbar>
            <ion-icon slot="start" name="car-outline" class="icon-courier ion-padding-end"
              [style.color]="storeDesignOptions.color"></ion-icon>
            <span class="bold font-18" [style.color]="storeDesignOptions.color" translate>DeliveryType</span>
          </ion-toolbar>
        </div>
        <div>
          <ng-content *ngTemplateOutlet="deliveryTypeSegmentTemplate"></ng-content>
        </div>
        <hr *ngIf="!hideOutletName" class="solid">
        <div *ngIf="!deliveryAppService.isCurrentOutletOpenForDelievryType" [class.ion-padding-start]="!homePage"
          class="flex align-item-center ion-justify-content-between">
          <span class="error-color">
            <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.Delivery" translate>Delivery </span>
            <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.SelfPickup" translate>SelfPickup
            </span>
            <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn" translate>DineIn </span>
            <span translate>DeliveryTypeNotAvailableNow</span>
          </span>
          <div slot="end">
            <ion-button fill="clear" lines="none" [style.--color]="storeDesignOptions.color"
              (click)="outletsAppService.openChooseOutletModal();orderAppService.closeCartMenu()"
              translate>Edit</ion-button>
          </div>
        </div>
        <hr class="solid" *ngIf="!hideOutletName && !deliveryAppService.isCurrentOutletOpenForDelievryType">
        <!--marketplace show store name-->
        <div *ngIf="showStoreName" [class.ion-padding-start]="!homePage"
          [class.ion-padding-top]="!outletsAppService.viewDeliveryType()">
          <ion-toolbar>
            <div class="flex ion-align-items-center">
              <ion-icon name="storefront-outline" class="icon- ion-padding-end" [style.color]="storeDesignOptions.color">
              </ion-icon>
              <span class="bold font-18" [style.color]="storeDesignOptions.color" translate>FromStore</span>
            </div>
          </ion-toolbar>
        </div>
        <div [class.ion-padding-start]="!homePage" *ngIf="showStoreName">
          <ion-toolbar>
            <div class="flex align-item-center ion-justify-content-start">
              <ion-icon slot="start" name="location-outline" class="icon- ion-padding-end"></ion-icon>
                <span class="grid">
                  <a [href]="'https://'+relatedStore.domains" target="_blank">
                  <span class="ion-text-center bold">{{relatedStore.storeName}}</span>
                </a>
                </span>
            </div>
          </ion-toolbar>
        </div>
        <!--end marketplace show store name-->
        <div *ngIf="!hideOutletName" [class.ion-padding-start]="!homePage"
          [class.ion-padding-top]="!outletsAppService.viewDeliveryType()">
          <ion-toolbar>
            <div class="flex ion-align-items-center">
              <ion-icon name="storefront-outline" class="icon- ion-padding-end"
                [style.color]="storeDesignOptions.color">
              </ion-icon>
              <span class="bold font-18" [style.color]="storeDesignOptions.color" translate>FromOutlet</span>
            </div>
          </ion-toolbar>
        </div>
        <ng-container *ngIf="storeAppService.isMultiOutletEnabled">
          <app-outlet-list [isModal]="false" [isListOnly]="true" [outletChanged]="currentOutlet" [homePage]="homePage"
            (outletsList)="outletsList($event)" [itemSlug]="itemSlug"
            [deliveryTypeChanged]="deliveryAppService.currentDeliveryType">
          </app-outlet-list>
          <div *ngIf="showMore" class="ion-text-center">
            <a (click)="outletsAppService.openChooseOutletModal(itemSlug);orderAppService.closeCartMenu()"
              [style.color]="storeDesignOptions.color" style="text-align: center;" class="text-decoration-underline pointer-cursor"
              translate>More</a>
          </div>
        </ng-container>
        <div [class.ion-padding-start]="!homePage">
          <ion-toolbar *ngIf="!hideOutletName && !storeAppService.isMultiOutletEnabled">
            <div class="flex align-item-center ion-justify-content-start">
              <ion-icon slot="start" name="location-outline" class="icon- ion-padding-end"></ion-icon>
              <span class="grid">
                <span class="ion-text-center bold">{{getValue(currentOutlet)}}</span>
              </span>
            </div>
          </ion-toolbar>
          <ion-toolbar *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn">
            <div class="flex align-item-center ion-justify-content-between">
              <div class="flex ion-align-items-end">
                <ion-icon slot="start" name="browsers-outline" class="icon- ion-padding-end"></ion-icon>
                <span class="grid" *ngIf="!orderAppService.order.outletTable">
                  <span class="ion-text-center bold" translate>NoTable</span>
                </span>
                <div *ngIf="orderAppService.order.outletTable">
                  <span class="ion-text-center bold" translate>TableName</span>
                  <span class="ion-text-center bold">: {{orderAppService.order.outletTable?.name}}</span>
                </div>
              </div>
              <ion-button slot="end" fill="clear" lines="none" [style.--color]="storeDesignOptions.color"
                class="ion-no-margin" (click)="openChooseTableModal();orderAppService.closeCartMenu()" translate>
                <ng-container *ngIf="!sideMenucart">SelectTable</ng-container>
                <ng-container *ngIf="sideMenucart">Edit</ng-container>
              </ion-button>
            </div>
          </ion-toolbar>
        </div>
        <hr *ngIf="currentOutlet.availableForScheduledOrders" class="solid">
        <div *ngIf="currentOutlet.availableForScheduledOrders" [class.ion-padding-start]="!homePage">
          <div class="flex align-item-center ion-justify-content-between">
            <ion-toolbar class="flex ion-align-items-center">
              <ion-icon slot="start" name="time-outline" class="icon-courier ion-padding-end"
                [style.color]="storeDesignOptions.color"></ion-icon>
              <span class="bold font-18" [style.color]="storeDesignOptions.color">
                <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.Delivery"
                  translate>DeliveryTime</span>
                <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.SelfPickup"
                  translate>PickupTime</span>
                <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn" translate>DineInTime</span>
              </span>
            </ion-toolbar>
          </div>
        </div>
        <div *ngIf="currentOutlet.availableForScheduledOrders" [class.ion-padding-start]="!homePage">
          <ion-toolbar *ngIf="orderAppService.order?.date">
            <div class="flex align-item-center ion-justify-content-between">
              <div slot="start" class="flex">
                <ion-icon name="time-outline" class="icon- ion-padding-end"></ion-icon>
                <span class="bold">{{datePipe.transform(orderAppService.order?.date,
                  'dd/MM/yyyy - h:mm a')}} </span>
              </div>
            </div>
          </ion-toolbar>
          <ion-segment mode="ios" ngDefaultControl [(ngModel)]="orderAppService.deliveryTime"
            class="ion-padding-end w-100" [style.--background]="storeDesignOptions.frontColor">
            <ion-segment-button *ngIf="currentOutlet.availableForLiveOrders" value="asap"
              [style.color]="storeDesignOptions.color" (click)="setOrderDateEmpty()"
              [style.--color-checked]="storeDesignOptions.color">
              <span class="text-delivery-method" translate>ASAP</span>
            </ion-segment-button>
            <ion-segment-button value="scheduleOrder" [style.color]="storeDesignOptions.color"
              (click)="showDatePickerModal();orderAppService.closeCartMenu()"
              [style.--color-checked]="storeDesignOptions.color">
              <span class="text-delivery-method" translate>ScheduleOrder</span>
            </ion-segment-button>
          </ion-segment>
        </div>
        <div [class.ion-padding-start]="!homePage">
          <ng-content *ngTemplateOutlet="preperationTimeTemplate"></ng-content>
        </div>
        <hr class="solid">
      </div>
      <div *ngIf="!outletsAppService.viewDeliveryType() && !orderAppService.isDeliveryOrder()"
        class="ion-align-items-center flex ion-margin-top" [class.ion-padding-start]="!homePage">
        <ion-icon name="car-outline" class="icon-courier ion-padding-end" [style.color]="storeDesignOptions.color">
        </ion-icon>
        <div>
          <span class="bold font-18" [style.color]="storeDesignOptions.color" translate>DeliveryType</span>
        </div>
        <div class="grid ion-margin-start">
          <span class="font-18"
            *ngIf="orderAppService.getDeliveryType(orderAppService?.order)">{{trsnalteApp.translate(orderAppService.getDeliveryType(orderAppService?.order))}}</span>
        </div>
        <hr class="solid">
      </div>

      <ion-item lines="none"
        *ngIf="(sideMenucart || homePage) && orderAppService.loading && orderAppService.isDeliveryOrder() && storeOptions.enableQuickCheckout">
        <ion-spinner style="margin-inline-end: 7px;zoom: 0.7;margin: 0px auto;" [style.color]="storeDesignOptions.color"
          name="crescent"></ion-spinner>
      </ion-item>
      <div *ngIf="!((sideMenucart || homePage) && orderAppService.loading) && !checkoutPage
             && orderAppService.isDeliveryOrder() && storeOptions.enableQuickCheckout">
        <div [class.ion-padding-start]="!homePage">
          <ion-toolbar>
            <div class="flex ion-align-items-center">
              <ion-icon name="man-outline" class="icon- ion-padding-end" [style.color]="storeDesignOptions.color">
              </ion-icon>
              <span class="bold font-18" [style.color]="storeDesignOptions.color" translate>DeliverTo</span>
            </div>
            <div slot="end" *ngIf="orderAppService?.order?.address">
              <ion-button fill="clear" lines="none" [style.--color]="storeDesignOptions.color" (click)="editAddress()"
                translate>Edit</ion-button>
            </div>
          </ion-toolbar>
        </div>
        <div [class.ion-padding-start]="!homePage"
          *ngIf="orderAppService?.order?.address?.firstName || orderAppService?.order?.customer?.fullName">
          <ion-toolbar>
            <ion-icon slot="start" name="person-outline" class="icon- ion-padding-end"></ion-icon>
            <div class="flex align-item-center ion-justify-content-start">
              <span>{{orderAppService?.order?.address?.firstName ?
                addressAppService.getAddressRecipient(orderAppService?.order?.address) :
                orderAppService?.order?.customer?.fullName}}</span>
            </div>
          </ion-toolbar>
        </div>
        <div [class.ion-padding-start]="!homePage"
          *ngIf="orderAppService?.order?.address?.phoneNumber || orderAppService?.order?.customer?.phoneNumber">
          <ion-toolbar>
            <ion-icon slot="start" name="call-outline" class="icon- ion-padding-end"></ion-icon>
            <div class="flex align-item-center ion-justify-content-start">
              <span>{{orderAppService?.order?.address?.phoneNumber ? orderAppService?.order?.address?.phoneNumber
                :
                orderAppService?.order?.customer?.phoneNumber}}</span>
            </div>
          </ion-toolbar>
        </div>
        <div *ngIf="orderAppService?.order?.address?.email" [class.ion-padding-start]="!homePage">
          <ion-toolbar>
            <ion-icon slot="start" name="mail-outline" class="icon- ion-padding-end"></ion-icon>
            <div class="flex align-item-center ion-justify-content-start">
              <span>{{orderAppService?.order?.address?.email ? orderAppService?.order?.address?.email :
                orderAppService?.order?.customer?.email}}</span>
            </div>
          </ion-toolbar>
        </div>
        <div [class.ion-padding-start]="!homePage">
          <ion-toolbar *ngIf="orderAppService?.order?.address">
            <ion-icon slot="start" name="location-outline" class="icon- ion-padding-end"></ion-icon>
            <app-address-card [viewActions]="false" [showAddressOnly]="true"
              [shortAddress]="storeOptions.enableQuickCheckout" [address]="orderAppService?.order?.address">
            </app-address-card>
            <ion-button *ngIf="false" fill="clear" slot="end" lines="none">
              <ion-icon name="create-outline" [style.color]="storeDesignOptions.color"></ion-icon>
            </ion-button>
          </ion-toolbar>
          <ion-toolbar *ngIf="!orderAppService?.order?.address">
            <div class="flex align-item-center ion-justify-content-start">
              <ion-button fill="clear" lines="none" [style.--border-color]="storeDesignOptions.color"
                [style.--color]="'#f00'" class="text-transform-none" (click)="editAddress()" translate>
                NoAddressFoundNote</ion-button>
            </div>
          </ion-toolbar>
        </div>
        <hr class="solid">
      </div>
      <div *ngIf="!((sideMenucart || homePage) && orderAppService.loading) && !checkoutPage
            && orderAppService.isDeliveryOrder() && storeOptions.enableQuickCheckout">
        <div [class.ion-padding-start]="!homePage">
          <div class="flex align-item-center  ion-justify-content-between">
            <div class="flex ion-align-items-center">
              <ion-icon slot="start" name="car-outline" class="icon-courier ion-padding-end"
                [style.color]="storeDesignOptions.color"></ion-icon>
              <span class="bold font-18" [style.color]="storeDesignOptions.color" translate>DeliveryMethod</span>
            </div>
            <div slot="end">
              <ion-button fill="clear" lines="none" [style.--color]="storeDesignOptions.color"
                *ngIf="cartAppService.shoppingCart"
                (click)="openAddAddressModal(false , true);orderAppService.closeCartMenu();" translate>Edit
              </ion-button>
            </div>
          </div>
        </div>
        <div class="flex ion-justify-content-center" [class.ion-margin-top]="!cartAppService.shoppingCart">
          <app-delivery-method-card [viewNote]="true" [deliveryMethod]="orderAppService?.deliveryMethodZoneRate"
            class="w-100 ion-padding-horizontal" [showFreeDeliveryLimit]="true">
          </app-delivery-method-card>
        </div>
        <hr class="solid">
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="currentTheme == 'merch' || currentTheme == 'anvogue' || currentTheme == 'shopper' || currentTheme == 'restaurants'">
    <div *ngIf="!orderAppService.isShoppingCartEmpty || homePage" [class.container]="homePage" [class.p-1]="homePage">

      <div *ngIf="outletsAppService.viewDeliveryType()" class="shadow-card mb-8 overflow-hidden rounded-lg bg-white">
        <div class="bg-[#f9f9f9] py-2 px-2 ">
          <h3 class="text-xl font-bold" [style.color]="storeDesignOptions.color">{{translations.DeliveryType}}</h3>
        </div>
        <div class="py-3 px-2">
          <div class="mb-2 w-full">
            <div class="w-full inline-flex rounded-lg">
              <button *ngIf="outletsAppService.storeOutletsHasDelivery?.length>0"
                (click)="onChangeDeliveryType(deliveryType.Delivery)"
                [style.color]="orderAppService.order.deliveryType==deliveryType.Delivery?storeDesignOptions.frontColor:null"
                [style.background]="orderAppService.order.deliveryType==deliveryType.Delivery?storeDesignOptions.color:null"
                [class.rounded-l-lg]="!isRtl && showRoundedDeliveryButtons" [class.rounded-r-lg]="isRtl && showRoundedDeliveryButtons"
                class="w-full border-light inline-flex items-center justify-center border py-[10px] px-[12px] text-center text-base font-semibold text-black   transition-all sm:py-3 sm:px-2">
                <span class="px-2">
                  <svg width="15" height="15" viewBox="0 0 15 15" class="fill-current">
                    <path
                      d="M7.5 8.53125C9.42187 8.53125 10.9922 6.96094 10.9922 5.03906C10.9922 3.11719 9.42187 1.52344 7.5 1.52344C5.57812 1.52344 4.00781 3.09375 4.00781 5.01562C4.00781 6.9375 5.57812 8.53125 7.5 8.53125ZM7.5 2.34375C8.97656 2.34375 10.1719 3.53906 10.1719 5.01562C10.1719 6.49219 8.97656 7.6875 7.5 7.6875C6.02344 7.6875 4.82812 6.49219 4.82812 5.01562C4.82812 3.5625 6.02344 2.34375 7.5 2.34375Z" />
                    <path
                      d="M14.555 12.75C12.6097 11.0859 10.1019 10.1719 7.50034 10.1719C4.89878 10.1719 2.39096 11.0859 0.445651 12.75C0.258151 12.8906 0.234714 13.1484 0.398776 13.3359C0.539401 13.5 0.797214 13.5234 0.984714 13.3828C2.7894 11.8594 5.10971 11.0156 7.52378 11.0156C9.93784 11.0156 12.2582 11.8594 14.0628 13.3828C14.1332 13.4531 14.2269 13.4766 14.3207 13.4766C14.4378 13.4766 14.555 13.4297 14.6253 13.3359C14.766 13.1484 14.7425 12.8906 14.555 12.75Z" />
                  </svg>
                </span>
                {{translations.Delivery}}
              </button>
              <button *ngIf="outletsAppService.storeOutletsHasSelfPickup?.length>0"
                (click)="onChangeDeliveryType(deliveryType.SelfPickup)"
                [style.color]="orderAppService.order.deliveryType==deliveryType.SelfPickup?storeDesignOptions.frontColor:null"
                [style.background]="orderAppService.order.deliveryType==deliveryType.SelfPickup?storeDesignOptions.color:null"
                class="w-full border-light inline-flex items-center justify-center border-y py-[10px] px-[12px] text-center text-sm font-semibold text-black transition-all sm:py-3 sm:px-2 sm:text-base">
                <span class="px-2">
                  <svg width="15" height="15" viewBox="0 0 15 15" class="fill-current">
                    <path
                      d="M7.17188 4.40625H3.23438C3 4.40625 2.8125 4.59375 2.8125 4.82813C2.8125 5.0625 3 5.25 3.23438 5.25H7.17188C7.40625 5.25 7.59375 5.0625 7.59375 4.82813C7.59375 4.59375 7.38281 4.40625 7.17188 4.40625Z" />
                    <path
                      d="M3.23438 7.07813H5.03906C5.27344 7.07813 5.46094 6.89063 5.46094 6.65625C5.46094 6.42187 5.27344 6.23438 5.03906 6.23438H3.23438C3 6.23438 2.8125 6.42187 2.8125 6.65625C2.8125 6.89063 3 7.07813 3.23438 7.07813Z" />
                    <path
                      d="M6.25781 8.10938H3.21094C2.97656 8.10938 2.78906 8.29688 2.78906 8.53125C2.78906 8.76563 2.97656 8.95313 3.21094 8.95313H6.25781C6.49219 8.95313 6.67969 8.76563 6.67969 8.53125C6.67969 8.29688 6.49219 8.10938 6.25781 8.10938Z" />
                    <path
                      d="M11.3447 4.05468C10.8056 3.98437 10.3134 4.24218 10.0556 4.71093C9.9384 4.92187 10.0322 5.15625 10.2197 5.27343C10.4306 5.39062 10.665 5.29687 10.7822 5.10937C10.8759 4.94531 11.0634 4.85156 11.2509 4.875C11.4618 4.89843 11.6259 5.0625 11.6493 5.25C11.6728 5.4375 11.579 5.60156 11.415 5.67187C11.0634 5.83593 10.8056 6.25781 10.8056 6.65625V6.96093C10.8056 7.19531 10.9931 7.38281 11.2275 7.38281C11.4618 7.38281 11.6493 7.19531 11.6493 6.96093V6.65625C11.6493 6.58593 11.7431 6.44531 11.8134 6.42187C12.2822 6.1875 12.5634 5.69531 12.4931 5.15625C12.3993 4.57031 11.9306 4.125 11.3447 4.05468Z" />
                    <path
                      d="M11.2031 8.17969C10.8516 8.17969 10.5938 8.46094 10.5938 8.78906C10.5938 9.14062 10.875 9.39844 11.2031 9.39844C11.5547 9.39844 11.8125 9.11719 11.8125 8.78906C11.8359 8.46094 11.5547 8.17969 11.2031 8.17969Z" />
                    <path
                      d="M12.9609 2.20312H2.03906C1.07813 2.20312 0.304688 2.97656 0.304688 3.9375V11.7422C0.304688 12.1172 0.492188 12.4453 0.820313 12.6328C0.984375 12.7266 1.14844 12.7734 1.33594 12.7734C1.52344 12.7734 1.6875 12.7266 1.85156 12.6328L4.57031 11.0625C4.59375 11.0391 4.64063 11.0391 4.66406 11.0391H12.9844C13.9453 11.0391 14.7188 10.2656 14.7188 9.30469V3.96094C14.7188 3 13.9219 2.20312 12.9609 2.20312ZM13.8984 9.32812C13.8984 9.84375 13.4766 10.2422 12.9844 10.2422H4.64063C4.45313 10.2422 4.28906 10.2891 4.125 10.3828L1.42969 11.9531C1.33594 12 1.24219 11.9766 1.21875 11.9531C1.19531 11.9297 1.125 11.8828 1.125 11.7656V3.96094C1.125 3.44531 1.54688 3.04688 2.03906 3.04688H12.9609C13.4766 3.04688 13.875 3.46875 13.875 3.96094V9.32812H13.8984Z" />
                  </svg>
                </span>
                {{translations.SelfPickup}}
              </button>
              <button *ngIf="outletsAppService.storeOutletsHasDineIn?.length>0"
                (click)="onChangeDeliveryType(deliveryType.DineIn)"
                [style.color]="orderAppService.order.deliveryType==deliveryType.DineIn?storeDesignOptions.frontColor:null"
                [style.background]="orderAppService.order.deliveryType==deliveryType.DineIn?storeDesignOptions.color:null"
                [class.rounded-l-lg]="isRtl && showRoundedDeliveryButtons" [class.rounded-r-lg]="!isRtl && showRoundedDeliveryButtons"
                class="w-full border-light   inline-flex items-center justify-center border py-[10px] px-[12px] text-center text-sm font-semibold text-black transition-all  sm:py-3 sm:px-2 sm:text-base">
                <span class="px-2">
                  <svg width="15" height="15" viewBox="0 0 15 15" class="fill-current">
                    <path
                      d="M12.5391 0.28125H11.3438C10.4063 0.28125 9.63281 1.05469 9.63281 1.99219V11.3906C9.63281 11.4609 9.65625 11.5313 9.70313 11.6016L11.4141 14.4141C11.5313 14.6016 11.7422 14.7188 11.9766 14.7188C12.2109 14.7188 12.4219 14.6016 12.5391 14.4141L14.25 11.6016C14.2969 11.5313 14.3203 11.4609 14.3203 11.3906V1.99219C14.25 1.05469 13.4766 0.28125 12.5391 0.28125ZM11.3438 1.10156H12.5391C13.0312 1.10156 13.4297 1.5 13.4297 1.99219V2.78906H10.4531V1.99219C10.4531 1.5 10.8516 1.10156 11.3438 1.10156ZM11.9297 13.7344L10.4297 11.2734V3.60938H13.4062V11.2734L11.9297 13.7344Z" />
                    <path
                      d="M5.27344 0.304688H2.10938C1.35938 0.304688 0.75 0.914063 0.75 1.66406V13.3594C0.75 14.1094 1.35938 14.7188 2.10938 14.7188H5.27344C6.02344 14.7188 6.63281 14.1094 6.63281 13.3594V1.66406C6.60938 0.914063 6 0.304688 5.27344 0.304688ZM5.78906 13.3359C5.78906 13.6172 5.55469 13.875 5.25 13.875H2.10938C1.82813 13.875 1.57031 13.6406 1.57031 13.3359V12.3047C1.59375 12.3047 1.64062 12.3281 1.6875 12.3281H3.42188C3.65625 12.3281 3.84375 12.1406 3.84375 11.9063C3.84375 11.6719 3.65625 11.4844 3.42188 11.4844H1.6875C1.64062 11.4844 1.61719 11.4844 1.57031 11.5078V10.0078C1.59375 10.0078 1.64062 10.0313 1.6875 10.0313H2.20312C2.4375 10.0313 2.625 9.84375 2.625 9.60938C2.625 9.375 2.4375 9.1875 2.20312 9.1875H1.6875C1.64062 9.1875 1.61719 9.1875 1.57031 9.21094V7.73438C1.59375 7.73438 1.64062 7.75781 1.6875 7.75781H3.42188C3.65625 7.75781 3.84375 7.57031 3.84375 7.33594C3.84375 7.10156 3.65625 6.91406 3.42188 6.91406H1.6875C1.64062 6.91406 1.61719 6.91406 1.57031 6.9375V5.4375C1.59375 5.4375 1.64062 5.46094 1.6875 5.46094H2.20312C2.4375 5.46094 2.625 5.27344 2.625 5.03906C2.625 4.80469 2.4375 4.61719 2.20312 4.61719H1.6875C1.64062 4.61719 1.61719 4.61719 1.57031 4.64063V3.14063C1.59375 3.14063 1.64062 3.16406 1.6875 3.16406H3.42188C3.65625 3.16406 3.84375 2.97656 3.84375 2.74219C3.84375 2.50781 3.65625 2.34375 3.42188 2.34375H1.6875C1.64062 2.34375 1.61719 2.34375 1.57031 2.36719V1.66406C1.57031 1.38281 1.80469 1.125 2.10938 1.125H5.27344C5.55469 1.125 5.8125 1.35938 5.8125 1.66406V13.3359H5.78906Z" />
                  </svg>
                </span>
                {{translations.DineIn}}
              </button>
            </div>
          </div>
          <div *ngIf="!deliveryAppService.isCurrentOutletOpenForDelievryType" class="flex align-item-center">
            <span class="error-color">
              <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.Delivery" translate>Delivery </span>
              <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.SelfPickup" translate>SelfPickup
              </span>
              <span *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn" translate>DineIn </span>
              <span translate>DeliveryTypeNotAvailableNow</span>
            </span>
            <div slot="end">
              <button class="mx-2" fill="clear" lines="none" [style.color]="storeDesignOptions.color"
                (click)="outletsAppService.openChooseOutletModal();orderAppService.closeCartMenu()"
                translate>Edit</button>
            </div>
          </div>
          <ng-container *ngIf="showStoreName">
            <div class="flex">
              <div class="mx-2 mt-1 w-5">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.00039 0.365234C5.09101 0.365234 1.88477 3.45898 1.88477 7.22773C1.88477 10.2371 6.18789 15.2152 8.04414 17.2402C8.29726 17.5215 8.63476 17.6621 9.00039 17.6621C9.36601 17.6621 9.70351 17.5215 9.95664 17.2402C11.8129 15.2434 16.116 10.2371 16.116 7.22773C16.116 3.43086 12.9098 0.365234 9.00039 0.365234ZM9.22539 16.5652C9.08476 16.7059 8.88789 16.7059 8.77539 16.5652C7.39726 15.0746 2.86914 9.95586 2.86914 7.22773C2.86914 3.96523 5.62539 1.34961 9.00039 1.34961C12.3754 1.34961 15.1316 3.99336 15.1316 7.22773C15.1316 9.95586 10.6035 15.0465 9.22539 16.5652Z"
                    fill="#3056D3" />
                  <path
                    d="M9.00039 4.41504C7.34101 4.41504 5.96289 5.76504 5.96289 7.45254C5.96289 9.11191 7.31289 10.49 9.00039 10.49C10.6879 10.49 12.0379 9.14004 12.0379 7.45254C12.0379 5.76504 10.6598 4.41504 9.00039 4.41504ZM9.00039 9.47754C7.87539 9.47754 6.94727 8.54941 6.94727 7.42441C6.94727 6.29941 7.87539 5.37129 9.00039 5.37129C10.1254 5.37129 11.0535 6.29941 11.0535 7.42441C11.0535 8.54941 10.1254 9.47754 9.00039 9.47754Z"
                    fill="#3056D3" />
                </svg>
              </div>
              <div>
                <h5 class="mb-1 text-lg font-semibold text-black">
                  <a [href]="'https://'+relatedStore.domains" target="_blank">
                    <span class="ion-text-center bold">{{relatedStore.storeName}}</span>
                  </a>
                </h5>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="storeAppService.isMultiOutletEnabled">
            <app-outlet-list [isModal]="false" [isListOnly]="true" [outletChanged]="currentOutlet" [homePage]="homePage"
              (outletsList)="outletsList($event)" [itemSlug]="itemSlug"
              [deliveryTypeChanged]="deliveryAppService.currentDeliveryType">
            </app-outlet-list>
            <div *ngIf="showMore" class="mb-2" style="text-align: center;">
              <a (click)="outletsAppService.openChooseOutletModal(itemSlug);orderAppService.closeCartMenu()"
                [style.color]="storeDesignOptions.color" class="text-decoration-underline pointer-cursor"
                translate>More</a>
            </div>
          </ng-container>
          <ng-container *ngIf="!storeAppService.isMultiOutletEnabled && !showStoreName">
            <div class="flex">
              <div class="mx-2 mt-1 w-5">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.00039 0.365234C5.09101 0.365234 1.88477 3.45898 1.88477 7.22773C1.88477 10.2371 6.18789 15.2152 8.04414 17.2402C8.29726 17.5215 8.63476 17.6621 9.00039 17.6621C9.36601 17.6621 9.70351 17.5215 9.95664 17.2402C11.8129 15.2434 16.116 10.2371 16.116 7.22773C16.116 3.43086 12.9098 0.365234 9.00039 0.365234ZM9.22539 16.5652C9.08476 16.7059 8.88789 16.7059 8.77539 16.5652C7.39726 15.0746 2.86914 9.95586 2.86914 7.22773C2.86914 3.96523 5.62539 1.34961 9.00039 1.34961C12.3754 1.34961 15.1316 3.99336 15.1316 7.22773C15.1316 9.95586 10.6035 15.0465 9.22539 16.5652Z"
                    fill="#3056D3" />
                  <path
                    d="M9.00039 4.41504C7.34101 4.41504 5.96289 5.76504 5.96289 7.45254C5.96289 9.11191 7.31289 10.49 9.00039 10.49C10.6879 10.49 12.0379 9.14004 12.0379 7.45254C12.0379 5.76504 10.6598 4.41504 9.00039 4.41504ZM9.00039 9.47754C7.87539 9.47754 6.94727 8.54941 6.94727 7.42441C6.94727 6.29941 7.87539 5.37129 9.00039 5.37129C10.1254 5.37129 11.0535 6.29941 11.0535 7.42441C11.0535 8.54941 10.1254 9.47754 9.00039 9.47754Z"
                    fill="#3056D3" />
                </svg>
              </div>
              <div>
                <h5 class="mb-1 text-lg font-semibold text-black">
                  {{getValue(currentOutlet)}}
                </h5>
              </div>
            </div>
          </ng-container>

          <hr *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn" class="solid">
          <div class="flex mb-2 mt-2" *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn">
            <div class="mx-3 w-5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />
              </svg>
            </div>
            <div style="width: 100%;" class="flex justify-between">
              <p *ngIf="!orderAppService.order.outletTable" class="text-body-color text-base font-medium">
                <span class="bold">{{translations.NoTable}}</span>
              </p>
              <p *ngIf="orderAppService.order.outletTable" class="text-body-color text-base font-medium">
                <span class="bold">{{translations.TableName}}</span>
                <span class="bold">
                  : {{orderAppService.order.outletTable?.name}}</span>
              </p>
              <button class="mx-2" slot="end" fill="clear" lines="none" [style.color]="storeDesignOptions.color"
                (click)="openChooseTableModal();orderAppService.closeCartMenu()">
                <ng-container *ngIf="!sideMenucart">{{translations.SelectTable}}</ng-container>
                <ng-container *ngIf="sideMenucart">{{translations.Edit}}</ng-container>
              </button>
            </div>
          </div>

          <hr *ngIf="orderAppService.isDeliveryTimeDisplayed()" class="solid">
          <div class="flex mb-2 mt-2" *ngIf="orderAppService.isDeliveryTimeDisplayed()">
            <div class="mx-3 w-5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div>
              <p class="text-body-color text-base font-medium">
                <span class="bold" translate>PreperationTime</span>
                <span class="bold" *ngIf="orderAppService?.order?.deliveryType == deliveryType.Delivery">
                  ({{getDuration(currentOutlet?.deliveryLiveOrdersPreperationTime , true)}})</span>
                <span class="bold" *ngIf="orderAppService?.order?.deliveryType == deliveryType.SelfPickup">
                  ({{getDuration(currentOutlet?.selfPickupLiveOrdersPreperationTime , true)}})</span>
                <span class="bold" *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn">
                  ({{getDuration(currentOutlet?.dineInLiveOrdersPreperationTime , true)}})</span>
              </p>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="outletsAppService.viewDeliveryType() && currentOutlet.availableForScheduledOrders"
        class="shadow-card mb-8 overflow-hidden rounded-lg bg-white">
        <div class="bg-[#f9f9f9] py-2 px-2 ">
          <h3 class="text-xl font-bold" [style.color]="storeDesignOptions.color">
            <span>
              <span
                *ngIf="orderAppService?.order?.deliveryType == deliveryType.Delivery">{{translations.DeliveryTime}}</span>
              <span
                *ngIf="orderAppService?.order?.deliveryType == deliveryType.SelfPickup">{{translations.PickupTime}}</span>
              <span
                *ngIf="orderAppService?.order?.deliveryType == deliveryType.DineIn">{{translations.DineInTime}}</span>
            </span>
          </h3>
        </div>
        <div class="py-2 px-2">
          <div class="flex mb-2" *ngIf="orderAppService.order?.date">
            <div class="mx-3 w-5">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
              </svg>
            </div>
            <div>
              <p class="text-body-color text-base font-medium">
                {{datePipe.transform(orderAppService.order?.date,
                'dd/MM/yyyy - h:mm a')}}
              </p>
            </div>
          </div>

          <div class="mb-2 w-full">
            <div class="w-full inline-flex rounded-lg">
              <button *ngIf="currentOutlet.availableForLiveOrders"
                (click)="orderAppService.deliveryTime='asap'; setOrderDateEmpty()"
                [style.color]="orderAppService.deliveryTime=='asap'?storeDesignOptions.frontColor:null"
                [style.background]="orderAppService.deliveryTime=='asap'?storeDesignOptions.color:null"
                [class.rounded-l-lg]="!isRtl" [class.rounded-r-lg]="isRtl"
                class="w-full border-light inline-flex items-center justify-center border py-[10px] px-[12px] text-center text-base font-semibold text-black transition-all sm:py-3 sm:px-6">
                <span class="px-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                  </svg>
                </span>
                {{translations.ASAP}}
              </button>
              <button
                (click)="orderAppService.deliveryTime='scheduleOrder'; showDatePickerModal();orderAppService.closeCartMenu()"
                [style.background]="orderAppService.deliveryTime=='scheduleOrder'?storeDesignOptions.color:null"
                [style.color]="orderAppService.deliveryTime=='scheduleOrder'?storeDesignOptions.frontColor:null"
                [class.rounded-l-lg]="isRtl" [class.rounded-r-lg]="!isRtl"
                class="w-full border-light inline-flex items-center justify-center border py-[10px] px-[12px] text-center text-sm font-semibold text-black transition-all sm:py-3 sm:px-6 sm:text-base">
                <span class="px-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
                {{translations.ScheduleOrder}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>


<!-- <lib-default-delivery-type-and-time
[desginOptions]="desginOptions"
[configuration]="configuration"
[translations]="translations"
[isModal]="isModal"
[ouletList]="ouletList"
[isShoppingCartEmpty]="orderAppService.isShoppingCartEmpty"
[homePage]="homePage"
[isCurrentOutletOpenForDelievryType]="storeAppService.isCurrentOutletOpenForDelievryType"
[hideOutletName]="hideOutletName"
[isMultiOutletEnabled]="storeAppService.isMultiOutletEnabled"
[isListOnly]="true"
[compnentsRendered]="true"
[showMore]="showMore"
[sideMenucart]="sideMenucart"
[loading]="orderAppService.loading"
[checkoutPage]="checkoutPage"
[itemSlug]="itemSlug"
[isDeliveryTimeDisplayed]="orderAppService.isDeliveryTimeDisplayed()"
[viewDeliveryType]="outletsAppService.viewDeliveryType()"
[isDeliveryOrder]="orderAppService.isDeliveryOrder()"
[shoppingCart]="cartAppService.shoppingCart"
[storeOutletsHasDelivery]="outletsAppService.storeOutletsHasDelivery"
[storeOutletsHasSelfPickup]="outletsAppService.storeOutletsHasSelfPickup"
[storeOutletsHasDineIn]="outletsAppService.storeOutletsHasDineIn"
[currentOutlet]="currentOutlet"
[order]="orderAppService?.order"
[deliveryTime]="orderAppService.deliveryTime"
(closeDelivery)="close()"
(changeDeliveryType)="onChangeDeliveryType($event)"
(openChooseOutlet)="openChooseOutletModal()"
(closeCart)="closeCartMenu()"
(openChooseTable)="openChooseTableModal()"
(setOrderDateEmp)="setOrderDateEmpty()"
(showDatePicker)="showDatePickerModal()"
(openAddAddress)="openAddAddressModal()"
(changeDeliveryTim)="changeDeliveryTime($event)"
>
<ng-template #outletList>
  <app-outlet-list [isModal]="isModal" [isListOnly]="isListOnly" (outletsList)="outletsList($event)"
[selectedOutlet]="currentOutlet" [homePage]="homePage" (outletsList)="outletsList($event)"
[itemSlug]="itemSlug" [deliveryTypeChanged]="currentDeliveryType"></app-outlet-list>
</ng-template>

<ng-template #addressCard>
  <app-address-card style="width:100%" [viewActions]="viewActions" [showAddressOnly]="showAddressOnly"
    [shortAddress]="configuration.enableQuickCheckout" [address]="order?.addressLocation">
  </app-address-card>
</ng-template>

<ng-template #deliveryMethodCard>
  <app-delivery-method-card [selected]="true" [viewRadio]="true" [viewNote]="viewNote" [deliveryMethod]="deliveryMethodZoneRate"
       [showFreeDeliveryLimit]="showFreeDeliveryLimit">
  </app-delivery-method-card>
</ng-template>
</lib-default-delivery-type-and-time> -->