<ng-container *ngIf="currentTheme == 'ionic' || currentTheme == 'customizedIonic'">
  <ion-header [style.background]="storeDesignOptions?.color">
    <ion-toolbar [style.--background]="storeDesignOptions?.color" [style.color]="storeDesignOptions?.frontColor">
      <ion-title translate>{{trsnalteApp.getTranslateKey('ShoppingCart')}}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeCartMenu()">
          <ion-icon name="close-outline" slot="icon-only" class="font-24 ion-margin-end pointer-cursor">
          </ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div
      *ngIf="orderAppService.showMethodMinimumLimitError && currentOutlet?.minimumDeliveryLimit && orderAppService.isDeliveryOrder()"
      class="success-alert" [style.background]="storeDesignOptions?.color"
      [style.color]="storeDesignOptions?.frontColor" translate style="padding: 0.5rem;margin: 0.5rem;">
      <span translate>MinimumCartLimitMustBe</span>:
      <span>
        <span *ngIf="isCurrnetBefore">{{currnetSymbol}}</span>
        <span>{{displayCurrency(currentOutlet?.minimumDeliveryLimit)}}</span>
        <span *ngIf="isCurrnetAfter">{{currnetSymbol}}</span>
      </span>
    </div>
    <div *ngIf="orderAppService.showFreeLimit && orderAppService.isDeliveryOrder()" class="success-alert font-14"
      style="padding: 0.5rem;margin: 0.5rem;background: #c1e1ab" [style.color]="storeDesignOptions?.color" translate>
      <span translate>YouHaveReachedFreeDeliveryLimit</span>
    </div>
    <ion-item lines="none" *ngIf="cartAppService.loadingPoints && configFeatures?.loyaltyProgram">
      <ion-spinner style="margin-inline-end: 7px;zoom: 0.7;margin: 0px auto;" [style.color]="storeDesignOptions?.color"
        name="crescent"></ion-spinner>
    </ion-item>
    <ion-item lines="none"
      *ngIf="!orderAppService.isShoppingCartEmpty && !cartAppService.loadingPoints && customerAppService.hasLoggedIn && cartAppService.rewardPoints && configFeatures?.loyaltyProgram">
      <div class="get-rewards-content">
        <div class="get-rewards-icon">
          <ion-icon name="diamond-outline" [style.color]="storeDesignOptions?.color" slots="only-icon"></ion-icon>
        </div>
        <div class="get-rewards-text">
          <span class="text" [style.color]="storeDesignOptions?.color" translate>
            CartPointsNote
          </span>
          <span>{{cartAppService.rewardPoints}}</span>
          <span class="text" [style.color]="storeDesignOptions?.color" translate>
            CartPointsNote_2
          </span>
        </div>
      </div>
    </ion-item>
    <ion-item lines="none"
      *ngIf="!orderAppService.isShoppingCartEmpty && !cartAppService.loadingPoints && !customerAppService.hasLoggedIn && cartAppService.rewardPoints && configFeatures?.loyaltyProgram">
      <div class="get-rewards-content">
        <div class="get-rewards-icon">
          <ion-icon name="diamond-outline" [style.color]="storeDesignOptions?.color" slots="only-icon"></ion-icon>
        </div>
        <div class="get-rewards-text">
          <span class="text" [style.color]="storeDesignOptions?.color" translate>
            CheckoutPointsGuestNote
          </span>
          <span>{{cartAppService.rewardPoints}}</span>
          <span class="text" [style.color]="storeDesignOptions?.color" translate>
            CheckoutPointsGuestNote_2
          </span>
        </div>
      </div>
    </ion-item>
    <div *ngIf="!orderAppService.isShoppingCartEmpty">
      <app-delivery-type-and-time [sideMenucart]="true"></app-delivery-type-and-time>

      <div *ngFor="let cartLine of availableCartLines; let i=index">
        <app-item-wide-card [itemTenantId]="getItemsTenantId()" [quantity]="cartLine.quantity" [viewAddToCart]="false" [viewQuantity]="true"
          [viewRemoveBtn]="true" [price]="getMatchedItemDetail(cartLine)?.price" [itemStore]="cartLine.item"
          [total]="getOrderLineTotal(cartLine)" [description]="cartLine.note" [isSideMenuCart]="true"
          [showVariant]="true" [itemDetailId]="cartLine.itemDetailId" (removeClicked)="removeFromCart(cartLine)"
          [oldPriceUpdated]="cartLine.status === cartLineStatus.ItemPriceIncreased || cartLine.status === cartLineStatus.ItemPriceDecreased ? cartLine.price : 0">
        </app-item-wide-card>
      </div>
      <div *ngIf="orderAppService.shoppingLinesUnavailableCount > 0" class="ion-margin-top">
        <hr class="solid" />
        <div class="flex ion-align-items-center" style="margin: 0 1rem;">
          <div>
            <h2 style="margin: 0;">
              <span style="font-size: 20px;" translate>{{trsnalteApp.getTranslateKey('NotAvailableProductsNow')}}</span>
            </h2>
          </div>
        </div>
        <div *ngFor="let cartLine of unavailableCartLines; let i=index">
          <app-item-wide-card [itemTenantId]="getItemsTenantId()" [quantity]="cartLine.quantity" [viewAddToCart]="false" [viewQuantity]="true"
            [viewRemoveBtn]="true" [price]="getMatchedItemDetail(cartLine)?.price" [itemStore]="cartLine.item"
            [total]="cartLine.quantity*getMatchedItemDetail(cartLine)?.price" [description]="cartLine.note"
            [showVariant]="true" [itemDetailId]="cartLine.itemDetailId" (removeClicked)="removeFromCart(cartLine)"
            [oldPriceUpdated]="cartLine.status === cartLineStatus.ItemPriceIncreased || cartLine.status === cartLineStatus.ItemPriceDecreased ? cartLine.price : 0">
          </app-item-wide-card>
        </div>
      </div>
    </div>
    <div *ngIf="orderAppService.isShoppingCartEmpty" class="ion-text-center ion-margin-top">
      <ion-row class="ion-text-center">
        <div class="not-found-icon-container">
          <ion-icon class="not-found-icon" name="warning-outline"></ion-icon>
        </div>
        <ion-col size="12">
          <h4 translate>{{trsnalteApp.getTranslateKey('NoProductsFound')}}</h4>
        </ion-col>
      </ion-row>

      <ion-button [routerLink]="[getUrl('/catalog')]" (click)="closeCartMenu()"
        [style.--background]="storeDesignOptions?.color" [style.color]="storeDesignOptions?.frontColor"
        class="ion-no-margin" style="margin-bottom: 5rem">
        <ion-label [style.color]="storeDesignOptions?.frontColor" translate>StartShopping</ion-label>
        <ion-icon [style.color]="storeDesignOptions?.frontColor" slot="end" name="color-wand-outline" size="large">
        </ion-icon>
      </ion-button>
    </div>
  </ion-content>
  <ion-footer class="ion-padding" *ngIf="!orderAppService.isShoppingCartEmpty">
    <div class="flex ion-justify-content-between bold">
      <div class="font-16" translate>Subtotal</div>
      <div>
        <span *ngIf="isCurrnetBefore"> {{currnetSymbol}} </span>
        <span> {{displayCurrency(cartAppService.shoppingCart.subTotal)}} </span>
        <span *ngIf="isCurrnetAfter"> {{currnetSymbol}} </span>
      </div>
    </div>
    <div class="flex ion-justify-content-between"
      *ngIf="orderAppService?.order?.deliveryFees && storeOptions.enableQuickCheckout">
      <div class="font-16" translate>DelievryFees</div>
      <div>
        <span *ngIf="isCurrnetBefore"> {{currnetSymbol}} </span>
        <span> {{displayCurrency(orderAppService?.order?.deliveryFees)}} </span>
        <span *ngIf="isCurrnetAfter"> {{currnetSymbol}} </span>
      </div>
    </div>
    <div class="flex ion-justify-content-between bold"
      *ngIf="orderAppService?.order?.deliveryFees && storeOptions.enableQuickCheckout">
      <div class="font-16" translate>Total</div>
      <div>
        <span *ngIf="isCurrnetBefore"> {{currnetSymbol}} </span>
        <span> {{displayCurrency(orderAppService.shippingCartTotal)}} </span>
        <span *ngIf="isCurrnetAfter"> {{currnetSymbol}} </span>
      </div>
    </div>
    <div *ngIf="!orderAppService?.order?.deliveryFees" class="flex font-12" style="margin: 5px 0">
      <span style="margin-inline-end: 2px" *ngIf="cartAppService.shoppingCart.includedSubTotalTax>0"
        translate>PriceIncluded<span>.</span></span>
      <span translate>PlusDeliveryFees</span>
    </div>
    <ion-button class="basic-btn w-100 ion-text-start" style="margin: 5px auto"
      [style.--background]="storeDesignOptions?.color" [style.color]="storeDesignOptions?.frontColor"
      (click)="checkout()" *ngIf="orderAppService?.order?.deliveryType"
      [disabled]="orderAppService.loadingRemove || !deliveryAppService.isCurrentOutletOpenForDelievryType">
      <span translate>{{trsnalteApp.getTranslateKey('Checkout')}}</span>
    </ion-button>
    <ion-button class="basic-btn w-100 ion-text-start favorite-btn" style="margin: 5px auto"
      [disabled]="orderAppService.loadingRemove" [style.color]="storeDesignOptions?.color"
      (click)="closeCartMenu(); goToCartEdit()">
      <span translate>{{trsnalteApp.getTranslateKey('EditCart')}}</span>
    </ion-button>
  </ion-footer>
</ng-container>

<ng-container *ngIf="currentTheme == 'merch' || currentTheme == 'anvogue'  || currentTheme == 'shopper' || currentTheme == 'restaurants'">
  <section *ngIf="currentTheme != 'anvogue'" class="bg-white mb-2" [style.background]="storeDesignOptions?.color"
    [style.color]="storeDesignOptions?.frontColor">
    <div class="mx-auto">
      <div class="flex border-stroke items-center justify-between border-b md:flex">
        <div class="w-full">
          <h2 class="mx-2 text-xl font-semibold">
            {{translations[trsnalteApp.getTranslateKey('ShoppingCart')]}}
          </h2>
        </div>
        <div>
          <button (click)="closeCartMenu()" [style.background]="storeDesignOptions?.color">
            <span class="mx-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
  </section>
  <div [style.background]="storeDesignOptions.minimumDeliveryLimitColor"
    [style.color]="storeDesignOptions.minimumDeliveryLimitFrontColor"
    *ngIf="orderAppService.showMethodMinimumLimitError && currentOutlet?.minimumDeliveryLimit && orderAppService.isDeliveryOrder()"
    class="border-stroke mb-2 flex items-center rounded-md border border-l-[8px] border-l-[#F0A92D] p-5 px-8">
    <div class="mx-5 flex h-[36px] w-full max-w-[36px] items-center justify-center rounded-full bg-[#F0A92D]">
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.42572 16H16.5811C17.6404 16 18.3233 14.9018 17.844 13.9735L10.2664 0.770573C9.7439 -0.256858 8.24852 -0.256858 7.72606 0.770573L0.148374 13.9735C-0.316489 14.8998 0.366408 16 1.42572 16ZM10.1923 12.9298C10.1923 13.6155 9.68425 14.1433 8.95815 14.1433C8.23206 14.1433 7.724 13.6155 7.724 12.9298V12.9015C7.724 12.2159 8.23206 11.688 8.95815 11.688C9.68425 11.688 10.1923 12.2159 10.1923 12.9015V12.9298ZM8.29171 4.01062H9.7151C10.1059 4.01062 10.3383 4.33826 10.2952 4.75288L9.66985 10.1186C9.62665 10.5049 9.36542 10.7455 9.00135 10.7455C8.63933 10.7455 8.37605 10.5028 8.33285 10.1186L7.70755 4.75288C7.66847 4.33826 7.9009 4.01062 8.29171 4.01062Z"
          fill="white" />
      </svg>
    </div>
    <div class="flex w-full items-center justify-between">
      <div>
        <h3 class="mb-1 text-lg font-medium">
          {{translations?.MinimumCartLimitMustBe}}:
          <span>
            <span *ngIf="isCurrnetBefore">{{currnetSymbol}}</span>
            <span>{{displayCurrency(currentOutlet?.minimumDeliveryLimit)}}</span>
            <span *ngIf="isCurrnetAfter">{{currnetSymbol}}</span>
          </span>
        </h3>
      </div>
    </div>
  </div>
  <div [style.background]="storeDesignOptions?.freeDeliveryLimitColor"
    *ngIf="orderAppService.showFreeLimit && orderAppService.isDeliveryOrder()"
    class="border-stroke mb-2 flex items-center rounded-md border border-l-[8px] border-l-[#00B078]  p-5 px-8">
    <div class="mx-5 flex h-[36px] w-full max-w-[36px] items-center justify-center rounded-full bg-[#00B078]">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M17.4038 4.22274C17.883 4.70202 17.883 5.47909 17.4038 5.95837L8.40377 14.9584C7.92449 15.4376 7.14742 15.4376 6.66814 14.9584L2.57723 10.8675C2.09795 10.3882 2.09795 9.61111 2.57723 9.13183C3.05651 8.65255 3.83358 8.65255 4.31286 9.13183L7.53595 12.3549L15.6681 4.22274C16.1474 3.74346 16.9245 3.74346 17.4038 4.22274Z"
          fill="white" />
      </svg>
    </div>
    <div class="flex w-full items-center justify-between">
      <div>
        <h3 class="mb-1 text-lg font-medium" [style.color]="storeDesignOptions?.freeDeliveryLimitFrontColor">
          {{translations?.YouHaveReachedFreeDeliveryLimit}}
        </h3>
      </div>
    </div>
  </div>
  <ng-container *ngIf="cartAppService.loadingPoints && configFeatures?.loyaltyProgram">
    <app-loading style="width: 100%;height: 150px;display: block;"></app-loading>
  </ng-container>
  <div class="mb-2" *ngIf="!cartAppService.loadingPoints && configFeatures?.loyaltyProgram">
    <div class="rounded-md bg-[#C4F9E2] p-4" *ngIf="customerAppService.hasLoggedIn && cartAppService.rewardPoints">
      <p class="flex items-center text-sm font-medium text-[#004434]">
        <span class="px-3">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
          </svg>
        </span>
        <span [style.color]="storeDesignOptions.color">
          {{translations?.CartPointsNote}}
        </span>
        <span class="mx-1">{{cartAppService.rewardPoints}}</span>
        <span [style.color]="storeDesignOptions.color">
          {{translations?.CartPointsNote_2}}
        </span>
      </p>
    </div>
    <div class="rounded-md bg-[#C4F9E2] p-4" *ngIf="!customerAppService.hasLoggedIn && cartAppService.rewardPoints">
      <p class="flex items-center text-sm font-medium text-[#004434]">
        <span class="px-3">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
          </svg>
        </span>
        <span [style.color]="storeDesignOptions.color">
          {{translations?.CheckoutPointsGuestNote}}
        </span>
        <span class="mx-1">{{cartAppService.rewardPoints}}</span>
        <span [style.color]="storeDesignOptions.color">
          {{translations?.CheckoutPointsGuestNote_2}}
        </span>
      </p>
    </div>
  </div>
  <div *ngIf="!orderAppService.isShoppingCartEmpty">
    <app-delivery-type-and-time [sideMenucart]="true"></app-delivery-type-and-time>

    <div class="space-y-8 px-2">
      <ng-container *ngFor="let cartLine of availableCartLines; let i=index">
        <app-item-wide-card [itemTenantId]="getItemsTenantId()" [quantity]="cartLine.quantity" [viewAddToCart]="false" [viewQuantity]="true"
          [viewRemoveBtn]="true" [price]="getMatchedItemDetail(cartLine)?.price" [itemStore]="cartLine.item"
          [cartLineId]="cartLine.id" [total]="getOrderLineTotal(cartLine)" [description]="cartLine.note"
          [isSideMenuCart]="true" [showVariant]="true" [itemDetailId]="cartLine.itemDetailId"
          (removeClicked)="removeFromCart(cartLine)"
          [oldPriceUpdated]="cartLine.status === cartLineStatus.ItemPriceIncreased || cartLine.status === cartLineStatus.ItemPriceDecreased ? cartLine.price : 0">
        </app-item-wide-card>
      </ng-container>
    </div>

    <div class="xs:px-4 mb-2 overflow-hidden rounded-xl border border-[#e7e7e7] bg-white py-4 px-4">
      <div class="border-b border-[#e7e7e7] pb-5">
        <h3 [style.color]="storeDesignOptions?.color" class="text-lg font-semibold sm:text-xl">
          {{translations?.OrderSummary}}
        </h3>
      </div>
      <div class="-mx-1 border-b border-[#e7e7e7] py-3">
        <div class="mb-3 flex items-center justify-between">
          <div class="px-1">
            <p class="text-base text-black">{{translations?.Subtotal}}</p>
          </div>
          <div class="px-1">
            <p class="text-base font-semibold text-black">
              <span *ngIf="isCurrnetBefore"> {{currnetSymbol}} </span>
              <span> {{displayCurrency(cartAppService.shoppingCart.subTotal)}} </span>
              <span *ngIf="isCurrnetAfter"> {{currnetSymbol}} </span>
            </p>
          </div>
        </div>
        <div *ngIf="orderAppService.order?.deliveryFees && storeDesignOptions?.enableQuickCheckout"
          class="flex items-center justify-between">
          <div class="px-1">
            <p class="text-base text-black">{{translations?.DelievryFees}}</p>
          </div>
          <div class="px-1">
            <p class="text-base font-semibold text-black">
              <span *ngIf="isCurrnetBefore"> {{currnetSymbol}} </span>
              <span> {{displayCurrency(orderAppService?.order?.deliveryFees)}} </span>
              <span *ngIf="isCurrnetAfter"> {{currnetSymbol}} </span>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!orderAppService?.order?.deliveryFees" class="flex font-12" style="margin: 5px 0">
        <span style="margin-inline-end: 2px" *ngIf="cartAppService.shoppingCart.includedSubTotalTax>0"
          translate>PriceIncluded<span>.</span></span>
        <span translate>PlusDeliveryFees</span>
      </div>
      <div class="-mx-1 flex items-center justify-between pt-5 pb-6">
        <div class="px-1">
          <p class="text-base font-semibold text-black">{{translations?.Total}}</p>
        </div>
        <div class="px-1">
          <p class="text-base font-semibold text-black">
            <span *ngIf="isCurrnetBefore"> {{currnetSymbol}} </span>
            <span> {{displayCurrency(orderAppService.shippingCartTotal)}} </span>
            <span *ngIf="isCurrnetAfter"> {{currnetSymbol}} </span>
          </p>
        </div>
      </div>

      <div class="mb-5">
        <button *ngIf="orderAppService.order?.deliveryType" [style.background]="storeDesignOptions.color"
          [style.color]="storeDesignOptions.frontColor"
          [disabled]="orderAppService.loadingRemove || !deliveryAppService.isCurrentOutletOpenForDelievryType"
          (click)="checkout()"
          class="bg-primary flex w-full items-center justify-center rounded-md py-[10px] px-10 text-center text-base font-normal text-white hover:bg-opacity-90">
          {{translations[trsnalteApp.getTranslateKey('Checkout')]}}
        </button>
        <button *ngIf="orderAppService.order?.deliveryType" [style.color]="storeDesignOptions.color"
          [disabled]="orderAppService.loadingRemove" [style.border-color]="storeDesignOptions.color"
          (click)="closeCartMenu(); goToCartEdit()"
          class="flex mt-2 w-full items-center justify-center rounded-md border bg-transparent py-[10px] px-10 text-center text-base font-medium">
          {{translations[trsnalteApp.getTranslateKey('EditCart')]}}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="orderAppService.isShoppingCartEmpty">
    <section class="relative z-10 flex min-h-screen items-center justify-center overflow-hidden bg-white py-10">
      <div class="container mx-auto" [style.color]="storeDesignOptions?.color">
        <div class="mx-auto w-full max-w-[570px] text-center">
          <span class="flex justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-12 h-12">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
            </svg>
          </span>

          <h2 class="mb-6 text-2xl font-bold">
            {{translations?.NoProductsFound}}
          </h2>

          <a [routerLink]="[getUrl('/catalog')]" (click)="closeCartMenu()" [style.background]="storeDesignOptions.color"
            [style.color]="storeDesignOptions.frontColor" href="javascript:void(0)"
            class="flex justify-center py-3 px-8 text-base font-medium hover:bg-opacity-90">
            {{translations?.StartShopping}}
          </a>
        </div>
      </div>
    </section>
  </div>
</ng-container>
