import { Injectable, Injector } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { PlatformAppService, StoreAppService } from '@storefront/ng.core';
import { StoreTemplateType, StorefrontDto, StorefrontService } from '@tajer/api';
import { environment } from 'src/environments/environment';
import { CookieAppService } from './cookie-app.service';
import { merchTheme } from 'src/app/theme/merch/core/model';
import { shopperTheme } from 'src/app/theme/shopper/core/model';
import { ionicTheme } from 'src/app/theme/ionic/core/model';

import { HtmlDocumentAppService } from './html-document-app.service';
import { ThemeDependencyType } from '../models/theme';
import { CartAppService } from './cart-app.service';
import { OutletsAppService } from './outlets-app.service';
import { ServerService } from './server.service';
import { Location } from '@angular/common';
import { restaurantsTheme } from 'src/app/theme/restaurants/core/model';
import { customizedIonicTheme } from 'src/app/theme/basic/core/model';
import { anvogueTheme } from 'src/app/theme/anvogue/core/model';

@Injectable({
  providedIn: 'root'
})
export class StorefrontAppService {
  //#region variables
  private transferState: TransferState;
  private dataDetailsKey = makeStateKey('app_details_configs');
  private dataThemeOptionsKey = makeStateKey('app_theme_options');
  private dataThemeComponentsKey = makeStateKey('app_theme_components');
  public loading: boolean;
  themeComponentsLoading: boolean;
  currentThemeParam: string;
  currentVersion: string;
  //#endregion

  constructor(private injector: Injector,
    private storefrontService: StorefrontService,
    private platformApp: PlatformAppService,
    public cookieAppService: CookieAppService,
    protected htmlDocApp: HtmlDocumentAppService,
    public cartAppService: CartAppService,
    private outletsAppService: OutletsAppService,
    private storeAppService: StoreAppService) {
    if (environment.application.ssrEnabled) {
      this.transferState = injector.get(TransferState);
    }

    this.getCurrentParams();
  }

  getCurrentParams() {
    if (this.isServer) {
      const serverService = this.injector.get(ServerService);
      const location = this.injector.get(Location);

      const url = new URL(serverService.getRequestHostUrl() + location.path());

      //get current theme form url
      this.currentThemeParam = url.searchParams.get('theme');
      if (environment.log) {
        console.log('----------------------');
        console.log('currentThemeParam: ' + this.currentThemeParam);
        console.log('----------------------');
      }

      //get version from url
      this.currentVersion = url.searchParams.get('version');
      if (environment.log) {
        console.log('----------------------');
        console.log('currentVersion: ' + this.currentVersion);
        console.log('----------------------');
      }

    } else {
      const url = new URL(window.location.href);
      this.currentThemeParam = url.searchParams.get('theme');
      if (environment.log) {
        console.log('----------------------');
        console.log('currentThemeParam: ' + this.currentThemeParam);
        console.log('----------------------');
      }

      this.currentVersion = url.searchParams.get('version');
      if (environment.log) {
        console.log('----------------------');
        console.log('currentVersion: ' + this.currentVersion);
        console.log('----------------------');
      }
    }
  }

  //#region ThemeComponents
  getServerThemeComponents() {
    this.themeComponentsLoading = true;
    this.storefrontService.geThemeComponent(
      'homepage',
      StoreTemplateType.Web,
      this.currentThemeParam,
      this.currentVersion?Number(this.currentVersion):null,
    ).subscribe(
      data => {
        this.storeAppService.storeConfig.theme = {
          code: this.storeAppService.storeConfig.theme?.code,
          options: this.storeAppService.storeConfig.theme?.options,
          components: data,
        }
        this.themeComponentsLoading = false;
        if (this.transferState) {
          this.transferState?.set<any>(this.dataThemeComponentsKey, this.storeAppService.storeConfig);
        }
      }
    );
  }

  getBrowserThemeComponents() {
    let components = this.transferState?.get<any>(this.dataThemeComponentsKey, null);
    //here the condition with components as the components loaded at runtime if we tranfer between pages.
    if (this.transferState && components) {
      this.storeAppService.storeConfig = { ...this.storeAppService.storeConfig, ...components };
      if (environment.log) {
        console.log("getBrowserThemeComponents", this.storeAppService.storeConfig)
      }
      //this.transferState.remove(this.dataThemeComponentsKey);
    } else {
      this.getServerThemeComponents();
    }
  }

  public populateThemeComponents() {
    if (this.isBrowser) {
      this.getBrowserThemeComponents();
    } else {
      this.getServerThemeComponents();
    }
  }
  //#endregion ThemeComponents

  //#region ThemeOptions
  getBrowserThemeOptions() {
    if (this.transferState) {
      this.storeAppService.storeConfig = { ...this.storeAppService.storeConfig, ...this.transferState.get<any>(this.dataThemeOptionsKey, null) };
      if (environment.log) {
        console.log("getBrowserThemeOptions", this.storeAppService.storeConfig)
      }
      this.transferState.remove(this.dataThemeOptionsKey);
    } else {
      // we run in not ssr mode; so get all data directly
      // this.getServerThemeOptions();
    }
  }

  getServerThemeOptions() {
    this.storefrontService.geThemeOptions(
      StoreTemplateType.Web,
      this.currentThemeParam,
      this.currentVersion?Number(this.currentVersion):null,
    ).subscribe(
      data => {
        this.storeAppService.storeConfig.theme = {
          code: data.themeCode,
          options: data.options,
          components: []
        }
        this.loadThemeDependenciesInServer();
        if (this.transferState) {
          this.transferState?.set<any>(this.dataThemeOptionsKey, this.storeAppService.storeConfig);
        }
      }
    );
  }

  public populateThemeOptions() {
    if (this.isBrowser) {
      this.getBrowserThemeOptions();
    } else {
     if(this.storeAppService.storeConfig){
        this.getServerThemeOptions();
      }
    }
  }
  //#endregion ThemeOptions

  //#region StoreDetailsConfigs

  getBrowserStoreDetailsConfigs() {
    if (this.transferState) {
      this.storeAppService.storeConfig = { ...this.storeAppService.storeConfig, ...this.transferState.get<any>(this.dataDetailsKey, null) };
      if (environment.log) {
        console.log("getBrowserStoreDetailsConfigs", this.storeAppService.storeConfig)
      }
      //get cart info
      this.getCartInfo();

      this.transferState.remove(this.dataDetailsKey);
    } else {
      // we run in not ssr mode; so get all data directly
      //this.getServerStoreDetailsConfigs();
    }

  }

  getServerStoreDetailsConfigs() {
    this.storefrontService.getInfo(
      this.cookieAppService.getOutletId() != null || this.cookieAppService.getOutletId() !== 'undefined' ? this.cookieAppService.getOutletId() : null,
      this.storeAppService.storeConfig?.isMarketplace,
      null
    ).subscribe(
      data => {
        this.storeAppService.storeConfig = { ...this.storeAppService.storeConfig, ...data } as StorefrontDto;
        if (this.transferState) {
          this.transferState?.set<any>(this.dataDetailsKey, this.storeAppService.storeConfig);
        } else {
          //run  without ssr
          //get cart info
          this.getCartInfo();
        }

        //multi currency store
        this.processCurrentCurrency(this.storeAppService.storeConfig);
      }
    );
  }

  public populateStoreDetailsConfig() {
    if (this.isBrowser) {
      this.getBrowserStoreDetailsConfigs();
    } else {
      if(this.storeAppService.storeConfig){
        this.getServerStoreDetailsConfigs();
      }
    }
  }
  //#endregion StoreDetailsConfigs

  public populateStoreConfig() {
    /**
   * For SSR only
   * Get store config from transfer state from server and use it without saving it when web app
   * Save
   */
    if (this.transferState && this.isBrowser
    ) {
      // if platform is browser
      // meaning it's SSR and store confis already fetched in the server so we get it from transfer state
      const dataBasicKey = makeStateKey('app_basic_configs');
      const appBasicConfigs = this.transferState.get<any>(dataBasicKey, null);
      if (appBasicConfigs) {
        this.storeAppService.storeConfig = { ...this.storeAppService.storeConfig, ...appBasicConfigs };
        this.outletsAppService.openSelectOutletWhenStoreIsOpend();
        if (environment.log) {
          console.log('appConfigs loaded from the server');
          console.log('appConfigs', this.storeAppService.storeConfig);
        }

        //get pageInfo
        this.storeAppService.getPageDetails();
        // to do after populating confgis in client
        this.transferState.remove(dataBasicKey);
      } else {
        if (environment.log) {
          console.log('appConfigs NOT loaded from the server');
        }
      }

      // const dataKey = makeStateKey('app_configs');
      // const cartInfoKey = makeStateKey('app_cart_info');

      // const appConfigs = this.transferState.get<any>(dataKey, null);
      // if (appConfigs) {
      //   this.storeAppService.storeConfig = appConfigs;
      //   this.outletsAppService.openSelectOutletWhenStoreIsOpend();
      //   if (environment.log) {
      //     console.log('appConfigs loaded from the server');
      //     console.log('appConfigs', this.storeAppService.storeConfig);
      //   }

      //   //get Cart Info
      //   const myCartInfo = this.transferState.get<any>(cartInfoKey, null);
      //   if (myCartInfo) {
      //     this.cartAppService.shoppingCart = myCartInfo;
      //     if (environment.log) {
      //       console.log('shoppingCart loaded from the server');
      //       console.log('cartAppService.shoppingCart', this.cartAppService.shoppingCart);
      //     }
      //   }
      //   //get pageInfo
      //   this.storeAppService.getPageDetails();
      //   // to do after populating confgis in client
      //   this.transferState.remove(dataKey);
      // } else {
      //   if (environment.log) {
      //     console.log('appConfigs NOT loaded from the server');
      //   }
      // }
    } else {
      //only for development with not ssr (ng serve)
      if (this.isBrowser && !environment.application.ssrEnabled) {
        console.log('appConfigs NOT loaded from the server2');
        //we get page details
        this.storeAppService.getPageDetails();
      }
    }

  }

  //used in multi currencies stores
  processCurrentCurrency(storeConfig: StorefrontDto) {
    if (storeConfig.currencies?.length > 0) {
      if (!this.cookieAppService.getCurrentCurrencyId()) {
        //set default currency as selected
        this.cookieAppService.setCurrentCurrencyId(storeConfig.currency?.id);
      }
    } else {
      //multi currencies feature is not enabled
      this.cookieAppService.setCurrentCurrencyId(null);
    }
  }

  async getCartInfo() {
    //get cart info
    let isMarketplace = this.storeAppService.storeConfig.isMarketplace;
    try {
      let cartInfo = null;

      if (isMarketplace) {
        //update returned related store list and add mareketplace info to the list in order to use them later to get the info
        this.storeAppService.storeConfig.relatedStores.push({
          tenantId: this.storeAppService.storeConfig.tenantId,
          storeId: this.storeAppService.storeConfig.id,
          outletId: this.storeAppService.storeConfig.outlet.id,
          averageRatings: null,
          saleType: null
        });

        let storedMarketTenant = this.cookieAppService.getMarketTenantId();
        let marketTenant = storedMarketTenant ? storedMarketTenant : this.storeAppService.storeConfig.tenantId
        cartInfo = await this.cartAppService.getMyCartMarketplace(marketTenant);
        //if cartInfo is null or empty (no shopping cart) then return the stored tenant to current store tenant
        if (!cartInfo || cartInfo.cartLines?.length == 0) {
          this.cookieAppService.SetMarketTenantId(this.storeAppService.storeConfig.tenantId);
        }
      } else {
        cartInfo = await this.cartAppService.getMyCart();
      }
      this.cartAppService.shoppingCart = cartInfo;
    } catch (error) {
      if (isMarketplace) {
        this.cookieAppService.SetMarketTenantId(this.storeAppService.storeConfig.tenantId);
      }
    }
  }

  private loadThemeDependenciesInServer() {
    let selectedTheme;
    switch (this.currentTheme) {
      case 'ionic':
        selectedTheme = ionicTheme;
        break;
      case 'customizedIonic':
        selectedTheme = customizedIonicTheme;
        break;
      case 'shopper':
        selectedTheme = shopperTheme;
        break;
      case 'restaurants':
        selectedTheme = restaurantsTheme;
        break;
        case 'anvogue':
          selectedTheme = anvogueTheme;
          break;
      default:
        selectedTheme = merchTheme
        break;
    }
    if(this.isGoogleFont(this.storeDesignOptions?.font)){
      this.htmlDocApp.loadGoogleFont(this.storeDesignOptions?.font);
    }else{//load from files
      this.htmlDocApp.loadFontUsingTTF(this.storeDesignOptions?.font);
    }
    // selectedTheme.dependencies.filter(x => x.type === ThemeDependencyType.Font).forEach(x => {
    //   this.htmlDocApp.loadGoogleFont(x.value);
    // });
    console.log("loadThemeDependenciesInServer", selectedTheme)

    selectedTheme.dependencies.filter(x => x.type === ThemeDependencyType.Style).forEach(x => {
      this.htmlDocApp.loadStyle(x.value);
    });
  }

  isGoogleFont(font: string){
    return !['Rebel Kids','Altone Trial Regular'].includes(font);
  }

  public loadThemeDependenciesInBrowser() {
    let selectedTheme;
    switch (this.currentTheme) {
      case 'ionic':
        selectedTheme = ionicTheme;
        break;
      case 'customizedIonic':
        selectedTheme = customizedIonicTheme;
        break;
      case 'shopper':
        selectedTheme = shopperTheme;
        break;
      case 'restaurants':
        selectedTheme = restaurantsTheme;
        break;
      case 'anvogue':
        selectedTheme = anvogueTheme;
        break;
      default:
        selectedTheme = merchTheme
        break;
    }
    console.log("htmlDocApp.loadScript", selectedTheme)
    selectedTheme.dependencies.filter(x => x.type === ThemeDependencyType.Script).map(x => {
      this.htmlDocApp.loadScript(x.value, () => { });
    });

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 2000);

  }


  protected get isServer(): boolean {
    return this.platformApp.isServer || (!environment.production && !environment.application.ssrEnabled);
  }
  protected get isBrowser(): boolean {
    return !this.platformApp.isServer;
  }

  get currentTheme(): string {
    return this.storeAppService?.currentTheme;
  }

  set currentTheme(themCode: string) {
    this.storeAppService.currentTheme = themCode;
  }

  get storeDesignOptions(): any {
    return this.storeAppService?.storeConfig?.theme?.options;
  }

}
