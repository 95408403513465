import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderComponent } from './header.component';
import { IonicHeaderModule } from '@storefront/ng.themes';
import { DeliveryTypeAndTimeModule } from '../delivery-type-and-time/delivery-type-and-time.module';
import { PickupDatePickerModule } from '../pickup-date-picker/pickup-date-picker.module';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { SideMenuCartModule } from '../side-menu-cart/side-menu-cart.module';


@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    IonicHeaderModule,
    DeliveryTypeAndTimeModule,
    PickupDatePickerModule,
    SharedModule,
    NgOptimizedImage,
    SideMenuCartModule,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports:[
    HeaderComponent
  ]
})
export class HeaderModule { }
